// @flow
import type { GatsbyBrowser } from 'gatsby'

import { onClickLink } from '@toggl/google-tag-manager'
import type { DataLayer, GTag } from '@toggl/google-tag-manager/src/types'

declare var window: { dataLayer: DataLayer, gtag: GTag }
declare var document: Document

export const onClientEntry: $ElementType<
  GatsbyBrowser,
  'onClientEntry'
> = () => {
  document.addEventListener('click', onClickLink)
}
