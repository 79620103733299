// @flow

export const MOBILE_VIEW_WIDTH = '900px'

export const ERROR_MESSAGES = {
  invalidNumber: 'Please input a number.',
  invalidHours: 'Please input a number from 0 to 23 for hours.',
  invalidMinutes: 'Please input a number from 0 to 59 for minutes.',
  invalidClockTime: 'Your end time must be later than your start time.',
  invalidBreakTime:
    'Your break out time must be later than your break in time.',
  invalidBreakStart: 'Your break start time is earlier than your start time.',
  invalidBreakEnd: 'Your break out time is later than your end time.',
  invalidTotalTime:
    'Your break time is more than your total time entered for today.',
}

export type ErrorCode = $Keys<typeof ERROR_MESSAGES> | null

export type Unit = 'hours' | 'minutes'

export type Time = {|
  +[Unit]: string,
|}

export type Day =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

export const DAYS: Day[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export type EntryName = 'clockIn' | 'breakIn' | 'breakOut' | 'clockOut'

export const ENTRIES: EntryName[] = [
  'clockIn',
  'breakIn',
  'breakOut',
  'clockOut',
]

export const ENTRY_LABELS: { [EntryName]: string } = {
  clockIn: 'Clock In',
  breakIn: 'Break In',
  breakOut: 'Break Out',
  clockOut: 'Clock Out',
}

export type DayCardState = {|
  +[EntryName]: Time,
  +subtotal: number,
  +errorCode: ErrorCode,
  +erroredFields: string[],
|}

export type TimeCardState = {|
  +[Day]: DayCardState,
  +total: number,
|}

export const getTime = ({ hours, minutes }: Time): number =>
  parseInt(hours || 0) * 60 + parseInt(minutes || 0)

export const getFormattedUnit = (value: number | string): string =>
  isNaN(value) ? String(value) : String(value).padStart(2, '0')

export const getFormattedHours = (time: number): string =>
  getFormattedUnit(Math.floor(time / 60))

export const getFormattedMinutes = (time: number): string =>
  getFormattedUnit(time % 60)

export const getFormattedTime = (time: number): string =>
  `${getFormattedHours(time)}:${getFormattedMinutes(time)}`
