// @flow

import * as React from 'react'

import { colors } from '@toggl/style'

type Props = {
  color?: string,
}

export const IconInstagram = ({ color }: Props) => (
  <svg viewBox='0 0 16 16' fill={color || colors.peach100}>
    <path d='M7.997.005c-2.172 0-2.444.01-3.297.048C3.85.092 3.268.227 2.76.425a3.92 3.92 0 00-1.417.922A3.92 3.92 0 00.42 2.764c-.198.509-.333 1.09-.372 1.941C.01 5.558 0 5.831 0 8.003s.01 2.444.048 3.297c.039.851.174 1.432.372 1.941.204.526.478.972.922 1.417.445.444.89.718 1.417.922.509.198 1.09.333 1.941.372.853.039 1.125.048 3.297.048s2.445-.01 3.298-.048c.851-.039 1.432-.174 1.941-.372a3.92 3.92 0 001.417-.922 3.92 3.92 0 00.922-1.417c.198-.509.333-1.09.372-1.941.039-.853.048-1.125.048-3.297s-.01-2.445-.048-3.298c-.039-.851-.174-1.432-.372-1.941a3.92 3.92 0 00-.922-1.417 3.92 3.92 0 00-1.417-.922c-.509-.198-1.09-.333-1.941-.372-.853-.039-1.126-.048-3.298-.048z' />
    <path
      d='M7.997 10.668a2.666 2.666 0 110-5.331 2.666 2.666 0 010 5.331zm0-6.772a4.107 4.107 0 100 8.213 4.107 4.107 0 000-8.213zM13.226 3.733a.96.96 0 11-1.92 0 .96.96 0 011.92 0'
      fill='#2C1338'
    />
  </svg>
)
