// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, fonts, media } from '@toggl/style'
import { Button } from '@toggl/ui'

const REFRESH_RATE_MS = 30

const ONE_HOUR = 3600000
const ONE_MINUTE = 60000
const ONE_SECOND = 1000

export const Timer = () => {
  const [elapsedTime, setElapsedTime] = React.useState(0)
  const [isRunning, setIsRunning] = React.useState(false)
  const timer = React.useRef(null)

  React.useEffect(() => () => clearInterval(timer.current), [])

  const startTimer = React.useCallback(() => {
    // We can't rely on REFRESH_RATE_MS inside the interval callback because
    // the browser may take more than that to run it if it's experiencing
    // slowness, so we use offsetTime to calculate the actual elapsed time.
    // Since we also run startTimer when resuming the timer after pausing,
    // we subtract elapsedTime from the initial the initial offsetTime.
    const offsetTime = performance.now() - elapsedTime
    timer.current = setInterval(
      () => setElapsedTime(performance.now() - offsetTime),
      REFRESH_RATE_MS
    )
    setIsRunning(true)
  }, [elapsedTime])

  const pauseTimer = React.useCallback(() => {
    clearInterval(timer.current)
    setIsRunning(false)
  }, [])

  const resetTimer = React.useCallback(() => {
    pauseTimer()
    setElapsedTime(0)
  }, [pauseTimer])

  const minutes = Math.floor((elapsedTime % ONE_HOUR) / ONE_MINUTE)
  const seconds = Math.floor((elapsedTime % ONE_MINUTE) / ONE_SECOND)
  const centiseconds = Math.floor((elapsedTime % ONE_SECOND) / 10)

  return (
    <Container>
      <TimeWrapper>
        <TimeEntity>
          {String(minutes).padStart(2, '0')}
          <TimeType>Minutes</TimeType>
        </TimeEntity>
        <Colon>:</Colon>
        <TimeEntity>
          {String(seconds).padStart(2, '0')}
          <TimeType>Seconds</TimeType>
        </TimeEntity>
        <Colon>:</Colon>
        <TimeEntity>
          {String(centiseconds).padStart(2, '0')}
          <TimeType>Centiseconds</TimeType>
        </TimeEntity>
      </TimeWrapper>
      <ButtonWrapper>
        {isRunning ? (
          <Button.Primary onClick={pauseTimer}>Pause</Button.Primary>
        ) : (
          <Button.Primary onClick={startTimer}>
            {elapsedTime > 0 ? 'Resume' : 'Start'}
          </Button.Primary>
        )}
        <SecondaryButton onClick={resetTimer}>Reset</SecondaryButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  margin: 30px auto;
  max-width: 1200px;
  text-align: center;
`

const ButtonWrapper: React.ComponentType<
  React.ElementConfig<'div'>
> = styled.div`
  margin: 20px 0 0;
  button {
    margin: 20px;
    display: inline-block;
  }
  ${media.mq[1]} {
    margin: 60px 0 0;
    button {
      font-size: clamp(1.5rem, 1.8vw, 3rem);
      height: auto;
      border-radius: 200px;
      padding: 1.25rem 2.5rem;
      min-width: 250px;
    }
  }
`

const TimeWrapper: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.inter};
  font-size: 17vw;
  line-height: 1;
  color: ${colors.white};
  ${media.mq[1]} {
    margin-top: 50px;
  }
`

const TimeEntity: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  font-family: ${fonts.inter};
  font-weight: 700;
  min-width: 24vw;
`

const TimeType: React.ComponentType<
  React.ElementConfig<'small'>
> = styled.small`
  display: none;
  ${media.mq[1]} {
    display: block;
    text-align: right;
    margin-right: 30px;
    font-size: clamp(1rem, 2vw, 1.4rem);
    font-weight: 400;
  }
`

const Colon: React.ComponentType<React.ElementConfig<'span'>> = styled.span`
  transform: translateY(-2.5vw);
`

const SecondaryButton: React.ComponentType<
  React.ElementConfig<typeof Button.SecondaryTrack>
> = styled(Button.SecondaryTrack)`
  background: ${colors.darkPurple80};
  color: ${colors.white};
  &:hover {
    background: ${colors.peach100};
    color: ${colors.pink100};
  }
`
