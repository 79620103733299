// @flow

import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import { colors, media, themes } from '@toggl/style'
import {
  Button,
  Footer,
  Global,
  Header,
  Navigation,
  useCloseOnBlurEffect,
  useDropdown,
} from '@toggl/ui'
import typeof HeaderAnnouncement from 'components/HeaderAnnouncement'

import { GlobalHeaderContents } from './header'
import { GlobalLoginTrigger } from './header/GlobalLogin'
import { LayoutRoot } from './LayoutRoot'

declare var process: WebProcess

type Props = {
  ...React.ElementProps<typeof LayoutRoot>,
  announcement?: React.Element<HeaderAnnouncement>,
  children?: React.Node,
  background?: string,
}

export const LayoutGlobal = ({
  children,
  announcement,
  background,
  ...rest
}: Props): React.Element<typeof LayoutRoot> => {
  const data = useStaticQuery(graphql`
    query layoutGlobalJobsQuery {
      allJobYaml(filter: { listed: { eq: true } }) {
        totalCount
      }
    }
  `)

  const openPositions = data.allJobYaml.totalCount

  return (
    <LayoutRoot {...rest}>
      <ThemeProvider theme={themes.global}>
        <Navigation.DropdownProvider>
          <GlobalHeader
            announcement={announcement}
            background={background}
            openPositions={openPositions}
          />
        </Navigation.DropdownProvider>
        {children}
        <Footer.Wrapper Link={Link} openPositions={openPositions} />
      </ThemeProvider>
    </LayoutRoot>
  )
}

const GlobalHeader = ({ announcement, openPositions, background }) => {
  const headerRef = React.useRef(null)
  const { isOpen, close: closeHeader } = useDropdown()
  useCloseOnBlurEffect(headerRef, closeHeader)

  return (
    <Header ref={headerRef}>
      {announcement ? announcement : null}

      <Navigation.Header
        background={background}
        logoWidth={['75px', '85px']}
        logo={
          <Link to='/' aria-label='Go to Toggl home page'>
            <StyledLogo open={isOpen} />
          </Link>
        }
        secondaryLinks={<GlobalLoginTrigger />}
        cta={
          <Navigation.DropdownBaseTrigger item='global-products'>
            <Button.Primary reducedDevicePadding>Try Toggl</Button.Primary>
          </Navigation.DropdownBaseTrigger>
        }
      >
        <GlobalHeaderContents openPositions={openPositions} />
      </Navigation.Header>
    </Header>
  )
}

const StyledLogo = styled(Global)`
  fill: ${colors.darkPurple100};

  ${media.mq[2]} {
    fill: ${({ open }) => (open ? colors.darkPurple100 : colors.yellow100)};
  }
`
