// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { Button, text } from '@toggl/ui'

import { getTrackSignupUrl } from '../../utils'

type Props = {|
  title?: string,
  paragraph?: string,
  ctaText?: string,
|}

declare var process: WebProcess

export function MDXCallToAction({ title, paragraph, ctaText }: Props) {
  return (
    <Container>
      <text.H3>{title ? title : 'Do better work with Toggl Track'}</text.H3>
      <text.P3>
        {paragraph
          ? paragraph
          : 'Time tracking can transform the way you work. Track time, get and share insightful reports and stop wondering where your day went.'}
      </text.P3>

      <a href={getTrackSignupUrl()}>
        <Button.Primary>
          {ctaText ? ctaText : 'Start tracking for free'}
        </Button.Primary>
      </a>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  margin: 40px -30px;
  padding: 40px 30px;
  max-width: 950px;
  background: ${colors.darkPurple100};
  position: relative;
  text-align: center;
  p {
    margin-top: 10px !important;
  }
  a {
    display: inline-block;
    margin-top: 30px;
    text-decoration: none;
  }
  h3 {
    margin-top: 0;
  }
  ${media.mq[1]} {
    margin: 40px 0;
    padding: 50px 60px;
  }
  ${media.mq[2]} {
    margin: 60px -80px;
  }
`
