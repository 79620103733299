// @flow

export const getAuthUrl = (fallback: string) => {
  const url = new URL(
    typeof window === 'undefined' ? fallback : window.location
  )

  if (
    /toggl\.(com|space)$/.test(url.hostname) &&
    !/public-web/.test(url.hostname)
  ) {
    return new URL('/auth', `${url.protocol}//track.${url.host}`).toString()
  }

  const defaultUrl = new URL('https://track.toggl.space/auth')
  defaultUrl.searchParams.set('trustedOrigin', url.origin)
  return defaultUrl.toString()
}
