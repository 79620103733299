// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { Button } from '@toggl/ui'
import { ConfirmDialog } from '@toggl/ui/src/dialogs'

import { PrintStyles } from './time-card-calculator/PrintStyles'
import {
  ACTIONS,
  HANDLERS,
  INITIAL_TIME_CARD,
  context,
} from './time-card-calculator/reducer'
import type { Action } from './time-card-calculator/reducer'
import { TimeTable } from './time-card-calculator/TimeTable'
import type { TimeCardState } from './time-card-calculator/utils'

export const TimeCardCalculator = () => {
  const [isResetModalOpen, setIsResetModalOpen] = React.useState(false)

  const [timeCard, dispatch] = React.useReducer<TimeCardState, Action>(
    (timeCard: TimeCardState, action: Action): TimeCardState =>
      HANDLERS[action.type](timeCard, action.payload),
    INITIAL_TIME_CARD
  )

  const handlePrint = () => window.print()
  const handleReset = () => setIsResetModalOpen(true)
  const onCancelReset = () => setIsResetModalOpen(false)
  const onConfirmReset = () => {
    setIsResetModalOpen(false)
    dispatch(ACTIONS.resetTimeCard())
  }

  return (
    <context.Provider value={dispatch}>
      <PrintStyles />
      <Container>
        <TimeTable timeCard={timeCard} />
        <ButtonBar>
          <Button.Primary onClick={handlePrint}>Print Time Card</Button.Primary>
          <Button.SecondaryTrack onClick={handleReset}>
            Reset
          </Button.SecondaryTrack>
        </ButtonBar>
      </Container>
      <ConfirmDialog
        isOpen={isResetModalOpen}
        title='Reset'
        body='Are you sure you want to reset all fields?'
        confirmText='Reset'
        cancelText='Cancel'
        onConfirm={onConfirmReset}
        onCancel={onCancelReset}
      />
    </context.Provider>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  gap: 50px;
`

const ButtonBar: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`
