// @flow

export function filterData(data: { [key: string]: any } | null) {
  return (
    data &&
    Object.keys(data).reduce<{ [key: string]: any }>((acc, key) => {
      if (typeof data[key] === 'function') {
        return acc
      }
      acc[key] = data[key]
      return acc
    }, {})
  )
}
