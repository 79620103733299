// @flow
import * as React from 'react'

import { colors } from '@toggl/style'

export const IconTwitter = ({
  color = colors.peach100,
  height = 16,
}: {
  color?: string,
  height?: string,
}) => (
  <svg height={height} viewBox='0 0 99 101' fill={color}>
    <path d='M58.9185 42.7446L95.7734 0H87.04L55.0388 37.1146L29.4796 0H0L38.6506 56.1238L0 100.948H8.73395L42.528 61.7539L69.5204 100.948H99L58.9163 42.7446H58.9185ZM46.9561 56.6183L43.04 51.0296L11.8809 6.56001H25.2957L50.4415 42.4484L54.3576 48.0371L87.0441 94.6865H73.6293L46.9561 56.6204V56.6183Z' />
  </svg>
)
