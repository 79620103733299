// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { Input } from '@toggl/ui/src/form/Input'
import * as text from '@toggl/ui/src/text'

const { useState, useEffect } = React

export const FTECalculator = () => {
  const [employees, setEmployees] = useState('')
  const [hoursWorked, setHoursWorked] = useState('')
  const [fte, setFTE] = useState('')

  useEffect(() => {
    const parsedEmployees = parseInt(employees)
    const parsedHoursWorked = parseFloat(hoursWorked)
    setFTE(
      isNaN(parsedEmployees) || isNaN(parsedHoursWorked)
        ? ''
        : ((parsedEmployees * 40 + parsedHoursWorked) / 40).toFixed(2)
    )
  }, [employees, hoursWorked])

  return (
    <Container>
      <Input
        id='employees'
        name='employees'
        label={<text.P2>Number of Full-Time Employees:</text.P2>}
        type='number'
        min='0'
        value={employees}
        onChange={(e) => setEmployees(e.target.value)}
      />
      <Input
        id='hoursWorked'
        name='hoursWorked'
        label={
          <React.Fragment>
            <text.P2>
              Sum of Weekly Hours Worked by Part-Time Employees:
            </text.P2>
            <small>
              (Add up all hours worked by part time employees in a week)
            </small>
          </React.Fragment>
        }
        type='number'
        min='0'
        value={hoursWorked}
        onChange={(e) => setHoursWorked(e.target.value)}
      />
      <Answer>
        <text.P2>You have</text.P2>
        <Input id='fte' name='fte' readonly type='text' value={fte} />
        <text.P2>full-time equivalent employees 🎉</text.P2>
      </Answer>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  margin-top: 10px;
  input {
    width: 100%;
    margin-top: 15px;
  }
  small {
    ${text.paragraph3};
    display: block;
    margin-top: 5px;
  }
`

const Answer = styled.div`
  margin-top: 35px;
  > * {
    flex: 0 0 auto;
    width: auto;
  }
  p {
    margin-top: 15px;
  }
  input {
    border: none;
    margin: 15px 0 0;
    max-width: 150px;
    background: ${colors.darkPurple90};
    pointer-events: none;
    font-size: 120%;
  }
  ${media.mq[1]} {
    display: flex;
    align-items: center;
    input {
      margin: 15px 20px 0;
    }
  }
`
