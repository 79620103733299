// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, fonts, media } from '@toggl/style'

import { Logo } from '../logos'
import * as text from '../text'

export function Product({
  badge,
  img,
  title,
  description,
  mediaWidth,
  mediaHeight,
}: {
  badge?: boolean,
  img: React.Node | string,
  title: React.Node | string,
  description: string,
  mediaWidth?: string,
  mediaHeight?: string,
}) {
  return (
    <StyledProduct>
      {typeof img === 'string' && (
        <img
          src={img}
          alt={description}
          width={mediaWidth}
          height={mediaHeight}
        />
      )}
      {typeof img !== 'string' && img}

      <div>
        <Title>
          {typeof title === 'string' &&
          ['track', 'plan', 'hire', 'work'].includes(title) ? (
            // $FlowFixMe[incompatible-cast] Add %check function.
            <Logo kind={(title: 'hire' | 'plan' | 'track' | 'work')} dark />
          ) : (
            title
          )}
        </Title>
        <text.P3 color={colors.darkPurple80}>{description}</text.P3>
      </div>

      {badge && <Badge>Special offer</Badge>}
    </StyledProduct>
  )
}

const Title = styled(text.H5)`
  color: ${colors.darkPurple100};
  svg {
    height: 28px;
    width: auto;
    margin-top: 8px;
  }
`

export const ProductsGroup: React.ComponentType<any> = styled.div`
  width: 100%;
  a {
    display: block;
    margin-bottom: 20px;
    position: relative;
    &:hover img {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    }
  }

  ${media.mq[2]} {
    display: flex;
    a {
      flex: 1;
      max-width: 350px;
      margin: 0 20px 0 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

const StyledProduct: React.ComponentType<any> = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  & > img {
    width: 40%;
    max-width: 140px;
    height: auto;
  }

  ${media.mq[2]} {
    display: inline-block;
    width: 100%;
    & > img {
      width: 100%;
      max-width: none;
      margin-bottom: 15px;
      padding: 8px;
      background: ${colors.orange10};
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      transition: 0.5s ease;
    }
  }
`

const Badge = styled.div`
  ${text.defaultText};
  line-height: 1;
  color: ${colors.peach50};
  background: ${colors.darkPurple90};
  padding: 20px 5px;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  text-align: center;
  position: absolute;
  left: -15px;
  bottom: -15px;
  ${ProductsGroup}:hover & {
    font-family: ${fonts.gtHaptikMediumRotalic};
  }
  ${media.mq[2]} {
    left: auto;
    bottom: auto;
    top: -10px;
    right: -15px;
  }
`
