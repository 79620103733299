// @flow

export const heading1 = '4.5rem' // 65
export const heading2 = '3rem' // 48
export const heading3 = '2rem' // 32
export const heading4 = '1.5rem' // 24
export const heading5 = '1.25rem' // 20

export const paragraph1 = '1.375rem' // 22
export const paragraph2 = '1.125rem' // 18
export const paragraph3 = '1rem' // 16
export const paragraph4 = '0.875rem' // 14
export const paragraph5 = '0.6875rem' // 11

export const defaultText = '1rem' // 16
