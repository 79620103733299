// @flow

import * as React from 'react'
import { convertYoutubeEmbedToPrivacyFriendlyUrl } from 'utils'

type Props = {|
  ...React.ElementProps<'iframe'>,
  src: string,
|}

export default function YoutubeEmbed({ src, ...props }: Props) {
  const [consented, setConsented] = React.useState(false)

  React.useEffect(() => {
    setConsented(window?.Cookiebot?.consent?.marketing)

    const handleConsent = () => {
      setConsented(!!window?.Cookiebot?.consent?.marketing)
    }

    window.addEventListener('CookiebotOnAccept', handleConsent)
    window.addEventListener('CookiebotOnDecline', handleConsent)

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleConsent)
      window.removeEventListener('CookiebotOnDecline', handleConsent)
    }
  }, [])

  return (
    <iframe
      {...props}
      src={consented ? src : convertYoutubeEmbedToPrivacyFriendlyUrl(src)}
    />
  )
}
