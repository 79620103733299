// @flow
import * as React from 'react'

import { context } from '../lib/context'
import * as session from '../lib/session'

export default function useSession(): [
  boolean,
  { create: (userId: number) => void, destroy: () => void }
] {
  const [hasSession, setHasSession] = React.useContext(context)

  return [
    hasSession,
    {
      create(userId: number) {
        session.create(userId)
        setHasSession(true)
      },
      destroy() {
        session.destroy()
        setHasSession(false)
      },
    },
  ]
}
