// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import { DayCardEntry } from './DayCardEntry'
import {
  ENTRIES,
  ENTRY_LABELS,
  MOBILE_VIEW_WIDTH,
  getFormattedTime,
} from './utils'
import type { Day, DayCardState } from './utils'

type Props = {|
  +day: Day,
  +dayCard: DayCardState,
|}

export const DayCard = ({ day, dayCard }: Props) => (
  <React.Fragment>
    <div>
      <strong>{day}</strong>
    </div>
    {ENTRIES.map((entry) => (
      <DayCardEntry
        key={entry}
        day={day}
        entry={entry}
        label={ENTRY_LABELS[entry]}
        time={dayCard[entry]}
        erroredFields={dayCard.erroredFields}
      />
    ))}
    <DaySummary>
      <div className='label'>Total</div>
      <div className='value'>{getFormattedTime(dayCard.subtotal)}</div>
    </DaySummary>
  </React.Fragment>
)

const DaySummary: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  > .label {
    display: none;
  }

  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    background: ${colors.darkPurple80};
    padding: 15px 5px;
    display: flex;
    align-items: center;
    gap: 30px;

    > .label {
      flex: 1;
      display: block;
    }

    > .value {
      flex: 1;
      text-align: left;
    }
  }
`
