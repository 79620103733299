function _gtmSrc(window, document) {
  const TB_CHROME =
    'https://chrome.google.com/webstore/detail/toggl-track-productivity/oejgccbfbmkkpaidnkphaiaecficdnfn'
  const TB_FIREFOX =
    'https://addons.mozilla.org/en-US/firefox/addon/toggl-button-time-tracker'
  const TB_EDGE =
    'https://microsoftedge.microsoft.com/addons/detail/toggl-track-productivity/nafkedmdnkdelpddmlkllfgdcpkelehh'
  const APP_IOS = 'https://apps.apple.com/us/app/toggl/id1291898086'
  const APP_ANDROID =
    'https://play.google.com/store/apps/details?id=com.toggl.giskard'
  const APP_DESKTOP = [
    'https://toggl.com/track/toggl-desktop/downloads/windows/stable/TogglTrack-windows64.exe',
    'https://apps.apple.com/us/app/toggl-track-hours-time-log/id1291898086',
  ]

  window._gtmLinkHandler = (e) => {
    const anchor = e.target.closest('a')
    const href = anchor ? anchor.href : ''
    if (href.includes(TB_CHROME)) {
      window._inspectAndTrack(
        'browser_extension.clickthrough',
        { type: 'chrome' },
        { heap: false }
      )
    }

    if (href.includes(TB_FIREFOX)) {
      window._inspectAndTrack(
        'browser_extension.clickthrough',
        { type: 'firefox' },
        { heap: false }
      )
    }

    if (href.includes(TB_EDGE)) {
      window._inspectAndTrack(
        'browser_extension.clickthrough',
        { type: 'edge' },
        { heap: false }
      )
    }

    if (href.includes(APP_IOS)) {
      window._inspectAndTrack(
        'mobile_app.clickthrough',
        { type: 'iOS' },
        { heap: false }
      )
    }

    if (href.includes(APP_ANDROID)) {
      window._inspectAndTrack(
        'mobile_app.clickthrough',
        { type: 'Android' },
        { heap: false }
      )
    }

    if (APP_DESKTOP.some((url) => href.includes(url))) {
      let type = ''

      if (href.includes('macos') || href.includes('apple')) {
        type = 'macOS'
      } else if (href.includes('windows')) {
        type = 'Windows'
      } else {
        // Impossible state but if ever happens we want can capture the href
        // and check what happened in case we missed something
        type = href
      }
      window._inspectAndTrack(
        'desktop_app.clickthrough',
        { type },
        { heap: false }
      )
    }
  }
  document.addEventListener('click', window._gtmLinkHandler)
}

export default `${_gtmSrc.toString()};_gtmSrc(window, document)`
