// @flow

import styled from '@emotion/styled'
import { Link } from 'gatsby'
import * as React from 'react'

import * as text from '../text'
type Props = {
  children?: React.Node | Node | string,
}

export const Underlined = ({ children, ...props }: Props) => {
  return <Root {...props}>{children}</Root>
}

const Root: React.ComponentType<any> = styled.span`
  border-bottom: 1px solid ${(p) => p.theme.primary};
  display: inline-block;
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`
