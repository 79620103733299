// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import { TimeUnitInput } from './TimeUnitInput'
import { MOBILE_VIEW_WIDTH } from './utils'
import type { Day, EntryName, Time } from './utils'

type Props = {|
  +day: Day,
  +entry: EntryName,
  +label: string,
  +time: Time,
  +erroredFields: string[],
|}

export const DayCardEntry = ({
  day,
  entry,
  label,
  time,
  erroredFields,
}: Props) => {
  const renderInput = (unit) => (
    <TimeUnitInput
      day={day}
      entry={entry}
      unit={unit}
      value={time[unit]}
      erroredFields={erroredFields}
    />
  )

  return (
    <Container>
      <Label>{label}</Label>
      <InputGroup>
        {renderInput('hours')}
        <span>:</span>
        {renderInput('minutes')}
      </InputGroup>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    background: ${colors.darkPurple90};
    padding: 15px 10px;
    display: flex;
    align-items: center;
    gap: 30px;
    > * {
      flex: 1;
    }
  }
`

const Label: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    display: block;
    text-align: right;
  }
`

const InputGroup: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`
