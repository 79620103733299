// @flow

import * as React from 'react'

import { colors } from '@toggl/style'

type IconProps = {
  color?: string,
}

export const IconGitHub = ({ color }: IconProps) => (
  <svg viewBox='0 0 16 16' version='1.1' fill={color || colors.peach100}>
    <path d='M8 .022c-4.42 0-8 3.663-8 8.18 0 3.616 2.292 6.682 5.47 7.762.4.077.547-.176.547-.393 0-.194-.007-.709-.01-1.39-2.226.492-2.695-1.098-2.695-1.098-.364-.945-.89-1.197-.89-1.197-.725-.507.056-.497.056-.497.803.057 1.225.843 1.225.843.714 1.25 1.873.89 2.33.68.072-.529.278-.889.507-1.094-1.777-.204-3.644-.908-3.644-4.042 0-.893.31-1.623.823-2.195-.09-.207-.36-1.039.07-2.165 0 0 .67-.22 2.2.838a7.524 7.524 0 012-.276c.68.004 1.36.095 2 .276 1.52-1.058 2.19-.838 2.19-.838.43 1.126.16 1.958.08 2.165.51.572.82 1.302.82 2.195 0 3.143-1.87 3.834-3.65 4.036.28.245.54.747.54 1.513 0 1.095-.01 1.975-.01 2.24 0 .215.14.47.55.389C13.71 14.88 16 11.812 16 8.203 16 3.685 12.418.022 8 .022z' />
  </svg>
)
