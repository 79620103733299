// @flow
import * as React from 'react'

import { colors } from '@toggl/style'

export const IconFacebook = ({
  color = colors.peach100,
  height = 16,
}: {
  color?: string,
  height?: number,
}) => (
  <svg height={height} viewBox='0 0 24 49' fill={color}>
    <path d='M15 49V26.6h8.4l.6-8.2h-9v-6.1c0-2.7.7-4.1 3.5-4.1h5.2L24 .4h-.8A41.4 41.4 0 0 0 18 0c-4 0-7 1.1-9 3.2a11.2 11.2 0 0 0-3 8v7.2H0v8.2h6V49h9z' />
  </svg>
)
