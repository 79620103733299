// @flow

import * as Sentry from '@sentry/gatsby'
import * as React from 'react'

import { initABTesting } from '@toggl/ab-testing'
import { inspectAndTrack } from '@toggl/analytics'
import { Context as AuthContext } from '@toggl/auth'
import { MDXProvider } from 'components'

import ErrorPage from '../components/ErrorPage'

declare var process: WebProcess

export const { ABTestingProvider, abTester } = initABTesting({
  trackingCallback: (experiment, result) => {
    inspectAndTrack('experiment_viewed', {
      experiment_id: experiment.key,
      variation_id: result.value,
    })
  },
})

export const Provider = ({
  children,
}: {
  children: React.Node,
}): React.Element<typeof Sentry.ErrorBoundary> => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <ABTestingProvider>
        <AuthContext.Provider>
          <MDXProvider>{children}</MDXProvider>
        </AuthContext.Provider>
      </ABTestingProvider>
    </Sentry.ErrorBoundary>
  )
}
