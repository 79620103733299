// @flow
import { graphql, useStaticQuery } from 'gatsby'

let pages: $ReadOnlyArray<string>

export const useAllSitePages = (): typeof pages => {
  const data: ?{
    +allSitePage: { +nodes: $ReadOnlyArray<{ +path: string }> },
  } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  if (!data) {
    throw new Error('Received empty query')
  }

  pages = pages ?? data.allSitePage.nodes.map<any>(({ path }) => path)

  return pages
}
