// @flow

import * as React from 'react'

import { colors } from '@toggl/style'

type IconProps = {
  color?: string,
}

export const IconYouTube = ({ color = colors.peach100 }: IconProps) => (
  <svg viewBox='0 0 23 16'>
    <path
      fill={color}
      d='M22.349 2.5A2.859 2.859 0 0020.338.489C18.552 0 11.409 0 11.409 0S4.267 0 2.481.47A2.917 2.917 0 00.47 2.5C0 4.286 0 7.989 0 7.989s0 3.721.47 5.488a2.86 2.86 0 002.011 2.011c1.805.489 8.928.489 8.928.489s7.143 0 8.929-.47a2.859 2.859 0 002.011-2.011c.47-1.786.47-5.489.47-5.489s.019-3.721-.47-5.507z'
    />
    <path fill='#2C1338' d='M9.135 11.41l5.94-3.421-5.94-3.421z' />
  </svg>
)
