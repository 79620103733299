// @flow
import * as React from 'react'

export const useCloseOnBlurEffect = (
  ref: { current?: any },
  closeCallback: () => void
) =>
  React.useLayoutEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      if (!ref.current?.contains(evt.target)) {
        closeCallback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [closeCallback, ref])
