// @flow

import * as React from 'react'

import * as colors from '../colors'

const common = {
  dark: {
    background: colors.darkPurple100,
    text: colors.peach100,
  },
  light: {
    background: colors.peach100,
    text: colors.darkPurple100,
  },
}

export const global = {
  primary: colors.yellow100,
  accent: colors.darkPurple100,
  borderColor: colors.peach80,
  background: colors.orange10,
  backgroundDesktop: colors.peach100,
  ...common,
}

export const hire: typeof global = {
  primary: colors.orange,
  accent: colors.white,
  borderColor: colors.peach80,
  background: colors.orange10,
  backgroundDesktop: colors.orange10,
  ...common,
}

export const plan: typeof global = {
  primary: colors.violet,
  accent: colors.white,
  borderColor: colors.peach110,
  background: colors.peach100,
  backgroundDesktop: colors.peach100,
  ...common,
}

export const track: typeof global = {
  primary: colors.pink100,
  accent: colors.white,
  borderColor: colors.peach110,
  background: colors.peach100,
  backgroundDesktop: colors.peach100,
  ...common,
}

export const work: typeof global = {
  primary: colors.teal100,
  accent: colors.white,
  borderColor: colors.peach110,
  background: colors.peach100,
  backgroundDesktop: colors.peach100,
  ...common,
}

export type Theme = typeof global

// If C is omitted, WithTheme<> returns only { theme: Theme }
export type WithTheme<C = any> = React.ElementConfig<C> & { theme: Theme }
