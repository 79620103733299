// @flow

import path from 'path'

import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

// Takes a mappings to a base or an array of from files in the uploads
// directory, and resolves their public URLs.
export const useAssets = <T: { [key: string]: ?string | string[] }>(
  files: T
): T => {
  const data = useStaticQuery(graphql`
    {
      files: allFile(filter: { relativeDirectory: { eq: "assets" } }) {
        nodes {
          base
          publicURL
        }
      }
    }
  `)

  if (!data) {
    throw new Error('Received empty query')
  }

  return React.useMemo(() => {
    const fileUrls = Object.fromEntries(
      data.files.nodes.map(({ base, publicURL }) => [base, publicURL])
    )

    const findFile = (base: string | mixed): string => {
      if (base === null || base === undefined) {
        return ''
      }

      if (typeof base !== 'string') {
        throw new Error(`Received path that's not a string`)
      }

      return fileUrls[path.basename(base)]
    }

    return Object.entries(files).reduce<T>((r, [name, base]) => {
      if (typeof base === 'string') {
        r[name] = findFile(base)
      }

      if (Array.isArray(base)) {
        r[name] = base.map(findFile)
      }

      return r
    }, ({}: any))
  }, [data, files])
}
