// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { Input, Track, text } from '@toggl/ui'

import { DayCard } from './DayCard'
import { ErrorRow } from './ErrorRow'
import {
  DAYS,
  ENTRIES,
  ENTRY_LABELS,
  ERROR_MESSAGES,
  MOBILE_VIEW_WIDTH,
  getFormattedTime,
} from './utils'
import type { TimeCardState } from './utils'

type Props = {|
  +timeCard: TimeCardState,
|}

export const TimeTable = ({ timeCard }: Props) => {
  React.useEffect(() => {
    function setWeekPlaceholders() {
      const currentDate = new Date()
      const currentDay = currentDate.getDay()
      const diffToMonday =
        currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
      const startOfWeek = new Date(currentDate.setDate(diffToMonday))
      const endOfWeek = new Date(
        startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000
      )

      const formatDate = (date) => {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [day, month, year].join('-')
      }
      const startDateInput = document.getElementById('start-date')
      const endDateInput = document.getElementById('end-date')
      if (startDateInput instanceof HTMLInputElement) {
        startDateInput.value = formatDate(startOfWeek)
      }
      if (endDateInput instanceof HTMLInputElement) {
        endDateInput.value = formatDate(endOfWeek)
      }
    }
    setWeekPlaceholders()
  }, [])
  return (
    <React.Fragment>
      <p className='no-print'>Enter your time in 24-hour format.</p>

      <Information>
        <InfoBox>
          <label htmlFor='name'>Employee name:</label>
          <Input id='name' name='name' type='text' placeholder={'Name'} />
        </InfoBox>
        <DateBox>
          <label htmlFor='start-date'>Start date:</label>
          <Input id='start-date' name='start-date' type='text' placeholder='' />
        </DateBox>
        <DateBox>
          <label htmlFor='end-date'>End date:</label>
          <Input id='end-date' name='end-date' type='text' placeholder='' />
        </DateBox>
      </Information>
      <Container>
        <HeaderRow>
          <div className='label'>Day</div>
          {ENTRIES.map((entryName) => (
            <div key={entryName}>{ENTRY_LABELS[entryName]}</div>
          ))}
          <div className='summary'>Total</div>
        </HeaderRow>
        {DAYS.map((day) => (
          <React.Fragment key={day}>
            <Row>
              <DayCard day={day} dayCard={timeCard[day]} />
            </Row>
            <ErrorRow visible={!!timeCard[day].errorCode}>
              {timeCard[day].errorCode !== null &&
                ERROR_MESSAGES[timeCard[day].errorCode]}
            </ErrorRow>
          </React.Fragment>
        ))}
        <FooterRow>
          <div className='label'>
            <strong>Total Hours</strong>
          </div>
          <div className='summary'>{getFormattedTime(timeCard.total)}</div>
        </FooterRow>
      </Container>
      <UseTogglTrack className='force-print' href='https://toggl.com/'>
        <text.P3 color={colors.darkPurple90}>
          Use Toggl Track, the free alternative to manual time cards
        </text.P3>
        <Track />
      </UseTogglTrack>
    </React.Fragment>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  flex-flow: column;
  ${text.paragraph3};
  @media screen and (min-width: ${MOBILE_VIEW_WIDTH}) {
    border-radius: 10px;
    border: 1px solid ${colors.darkPurple40};
    overflow: clip;
    background: ${colors.peach10};
    margin-left: -40px;
    margin-right: -40px;
  }
  ${media.mq[2]} {
    margin-left: -120px;
    margin-right: -120px;
  }
`

const Row: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  > * {
    flex: 2;
    text-align: center;

    :first-of-type {
      text-align: left;
    }

    :last-of-type {
      flex: 1;
      text-align: right;
    }
  }

  @media screen and (min-width: ${MOBILE_VIEW_WIDTH}) {
    gap: 0px;
    > * {
      padding: 8px 20px;
      color: ${colors.darkPurple90};
    }
    &:nth-of-type(4n) {
      background: ${colors.peach50};
      > * {
        padding: 15px 20px;
        color: ${colors.darkPurple90};
      }
    }
    input {
      color: ${colors.darkPurple90};
      border-color: ${colors.darkPurple30};
    }
  }

  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    flex-flow: column;
    align-items: stretch;
    gap: 5px;

    > *:first-of-type {
      text-align: center;
    }
  }
`

const HeaderRow: React.ComponentType<React.ElementConfig<'div'>> = styled(Row)`
  text-transform: uppercase;
  font-weight: bold;
  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    display: none;
  }
  @media screen and (min-width: ${MOBILE_VIEW_WIDTH}) {
    background: ${colors.peach100};
    line-height: 1.2;
    margin-bottom: 12px;
    > * {
      padding: 20px;
      color: ${colors.darkPurple90};
    }
  }
`

const FooterRow: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  border-top: 1px solid ${colors.darkPurple30};
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  @media screen and (min-width: ${MOBILE_VIEW_WIDTH}) {
    color: ${colors.darkPurple90};
    line-height: 1.2;
    > * {
      padding: 20px;
      color: ${colors.darkPurple90};
    }
  }
`

const UseTogglTrack: React.ComponentType<
  React.ElementConfig<'div'>
> = styled.div`
  text-align: center;
  display: none;
  p {
    margin: 80px 0 20px;
  }
  svg {
    max-width: 200px;
  }
`

const Information = styled.div`
  padding-top: 40px;
  border-top: 2px solid ${colors.darkPurple30};
  margin-bottom: 5px;
  display: flex;
  gap: 50px;
  @media screen and (min-width: ${MOBILE_VIEW_WIDTH}) {
    margin-left: -40px;
    margin-right: -40px;
  }
  ${media.mq[2]} {
    margin-left: -120px;
    margin-right: -120px;
  }
`

const InfoBox = styled.div`
  flex: 1;
  label {
    display: block;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 12px;
    color: ${colors.peach10};
    font-weight: bold;
  }
  input {
    width: 100%;
  }
`
const DateBox = styled(InfoBox)`
  flex: 0 0 auto;
  input {
    max-width: 150px;
  }
`
