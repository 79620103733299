// @flow

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import { colors, media, variables } from '@toggl/style'

import { useAutoPlayRef } from '../hooks/useAutoPlayRef'
import * as Responsive from '../responsive'
import * as text from '../text'

type Props = {
  skewed?: boolean,
  bgImage?:
    | React.Element<typeof StaticImage>
    | React.Element<typeof GatsbyImage>
    | React.Element<'img'>,
  bgVideo?: string,
  children: React.Node,
  solidColor?: string,
  ...
}
export const Wrapper = ({
  bgImage,
  bgVideo,
  skewed,
  children,
  solidColor,
  ...props
}: Props) => {
  const shouldAutoPlay = useAutoPlayRef()

  return (
    <Root {...props} solidColor={solidColor} skewed={skewed}>
      {bgVideo && (
        <Helmet>
          {bgVideo && (
            <link
              rel='preload'
              href={bgVideo}
              as='video'
              media={media.mqbp[1]}
            />
          )}
        </Helmet>
      )}

      {bgVideo ? (
        <>
          <Responsive.DesktopOnly>
            <VideoWrapper skewed={skewed}>
              <VideoStyled muted loop skewed={skewed} ref={shouldAutoPlay}>
                <source src={bgVideo} />
              </VideoStyled>
              <Mask />
            </VideoWrapper>
          </Responsive.DesktopOnly>
          {bgImage && <Responsive.MobileOnly>{bgImage}</Responsive.MobileOnly>}
        </>
      ) : (
        bgImage
      )}

      {children}
    </Root>
  )
}

export const Root: React.ComponentType<any> = styled.div`
  position: relative;
  min-height: ${200 - variables.headerHeight}px;

  ${(p) => (p.solidColor ? `background-color: ${p.solidColor};` : '')}

  ${media.mq[1]} {
    margin-top: -${variables.headerHeightFull}px;
  }

  > div > img {
    object-fit: cover;
  }

  > div > img,
  > div > .gatsby-image-wrapper,
  > .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;

    ${({ skewed }) =>
      skewed &&
      css`
        transform: rotate(10deg);
        height: calc(100% - 35px);
      `}

    img {
      mask-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 100%);
    }
  }

  > div > img {
    mask-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 100%);
  }
`

const VideoWrapper: React.ComponentType<any> = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${({ skewed }) =>
    skewed && 'animation: spinner 1s; height: calc(100% - 150px);'}
  animation-fill-mode: forwards;
  animation-delay: 0.4s;

  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(10deg);
    }
  }

  ${media.mq[3]} {
    ${({ skewed }) => skewed && 'height: calc(100% - 250px);'}
  }
`

const Mask = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
`

const VideoStyled = styled.video`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
`

export const ContentWrapper: React.ComponentType<any> = styled.div`
  position: relative;
  padding: 30px;

  ${(p) =>
    p.center
      ? `p {
    text-align: center;
  }`
      : ''}

  ${media.mq[0]} {
    padding-top: 100px;
  }

  ${media.mq[1]} {
    margin: ${variables.headerHeightFull * 2.5}px auto 0;
    max-width: 1200px;
    padding-bottom: 60px;
    padding-top: 20px;
  }
`

export const Title: React.ComponentType<any> = styled(text.H1)`
  color: ${colors.orange10};
  margin-bottom: 25px;
  text-align: ${(p) => (p.centered ? 'center' : 'left')};
  em {
    color: ${(p) => p.theme.primary};
  }
`

export const TitleWrapper: React.ComponentType<any> = styled.div`
  background: ${colors.darkPurple90};
  padding: 45px 0 15px;
  text-align: center;
  h1 {
    color: ${(p) => p.theme.primary};
    text-align: center;
  }
  ${media.mq[1]} {
    padding: 120px 0 0;
  }
`

export const Text: React.ComponentType<any> = styled(text.P2)`
  margin-top: 10px;
  margin-bottom: 25px;
  width: 100%;
`

export const CtaSection: React.ComponentType<any> = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  button,
  a {
    margin-top: 15px;
  }
  ${media.mq[1]} {
    flex-direction: row;
    a:not(:nth-of-type(1)) {
      margin-left: 25px;
    }
  }
`

export const LoginSmallTitle: React.ComponentType<any> = styled.div`
  ${text.paragraph2};
  color: ${colors.orange10};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

export const LoginWrapper: React.ComponentType<any> = styled(ContentWrapper)`
  ${media.mq[1]} {
    padding: 80px 0;
  }
`

export const ExtraPaddingWrapper: React.ComponentType<any> = styled(
  ContentWrapper
)`
  ${media.mq[0]} {
    padding-top: 100px;
  }
  ${media.mq[1]} {
    padding-top: 80px;
  }
`

// Must use important for animation here because the previous selector in ContentWrapper is using nth-of-type which is stronger
export const TitleBig: React.ComponentType<any> = styled.div`
  text-align: center;
  max-width: ${({ spaced }) => (spaced !== false ? '800px' : 'inherit')};
  margin: 0 auto;

  ${media.mq[1]} {
    margin: ${({ spaced }) =>
      spaced !== false ? '100px auto 80px auto' : '30px auto 0'};
  }

  ${media.mq[2]} {
    margin: ${({ spaced }) =>
      spaced !== false ? '100px auto 80px auto' : '0 auto'};
  }

  p {
    margin: 20px auto;
  }
`

export const AltWrapper: React.ComponentType<any> = styled(Wrapper)`
  margin-top: ${variables.headerHeight}px !important;
  ${media.mq[1]} {
    padding-top: ${variables.headerHeight}px;
  }
`

export const Form: React.ComponentType<any> = styled.form`
  margin-top: 40px;

  textarea {
    width: 100%;
  }

  button {
    margin: 40px auto;
  }

  ${media.mq[1]} {
    button {
      margin: 40px 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
`
