// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import { colors, fonts, media, variables } from '@toggl/style'
import {
  IconArrowBigLeft,
  IconDown,
  IconRight,
  IconUp,
  Responsive,
} from '@toggl/ui'

import * as text from '../text'

type DropdownContextValue<T> = {
  isOpen: boolean,
  selected: T | null,
  selectItem: (key: T | null) => void,
  close: () => void,
}

const DropdownContext = React.createContext<DropdownContextValue<string> | null>(
  null
)

export function DropdownProvider({ children }: { children: React.Node }) {
  const [selected, setSelected] = React.useState<string | null>(null)
  const value = React.useMemo(
    () => ({
      isOpen: !!selected,
      selected,
      selectItem: setSelected,
      close: () => setSelected(null),
    }),
    [selected]
  )
  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  )
}

export function useDropdown<T>(): DropdownContextValue<T> {
  const value = React.useContext(DropdownContext)
  if (!value) {
    throw new Error('useDropdown must be used within a DropdownProvider')
  }
  return ((value: any): DropdownContextValue<T>)
}

export function DropdownBaseTrigger(props: {
  children: React.Node,
  item: string,
}) {
  const { selectItem } = useDropdown()
  return React.useMemo(
    () =>
      React.cloneElement((props.children: any), {
        onClick: () =>
          selectItem((selected) =>
            selected === props.item ? null : props.item
          ),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  )
}

export function DropdownTrigger(props: { children: React.Node, item: string }) {
  const { selected, isOpen } = useDropdown()
  return (
    <DropdownBaseTrigger item={props.item}>
      <TriggerButton
        open={isOpen}
        ariaExpanded={selected === props.item}
        role='button'
        tabIndex={0}
      >
        {props.children}
        <DropdownTriggerCaret item={props.item} />
      </TriggerButton>
    </DropdownBaseTrigger>
  )
}

export function DropdownTriggerCaret(props: { item: string }) {
  const { selected, isOpen } = useDropdown()
  return (
    <React.Fragment>
      <Responsive.WideDesktopOnly>
        {selected === props.item ? <IconUp /> : <IconDown dark={isOpen} />}
      </Responsive.WideDesktopOnly>
      <Responsive.MobileAndTabletOnly>
        <IconRight />
      </Responsive.MobileAndTabletOnly>
    </React.Fragment>
  )
}

export function DropdownItem(props: {
  item: string,
  children: [React.Node, React.Node],
}) {
  return (
    <li>
      <DropdownTrigger item={props.item}>{props.children[0]}</DropdownTrigger>
      <DropdownItemContents item={props.item}>
        {props.children[1]}
      </DropdownItemContents>
    </li>
  )
}

export function DropdownItemContents(props: {
  item: string,
  children: React.Node,
}) {
  const { selected, close } = useDropdown()
  return selected === props.item ? (
    <DropdownContents>
      <Responsive.MobileAndTabletOnly>
        <BackContainer>
          <GoBack tabIndex={0} role='button' onClick={close}>
            <IconArrowBigLeft color={colors.darkPurple100} />
            <span>Back</span>
          </GoBack>
        </BackContainer>
      </Responsive.MobileAndTabletOnly>
      <ContentsWrapper>{props.children}</ContentsWrapper>
    </DropdownContents>
  ) : null
}

type DropdownColumnProps = {
  children: React.Node,
  title?: string,
  className?: string,
}

export function DropdownColumn({
  children,
  title,
  className,
}: DropdownColumnProps) {
  return (
    <Column className={className}>
      {title && <ColumnTitle color={colors.darkPurple60}>{title}</ColumnTitle>}
      <ColumnItems>{children}</ColumnItems>
    </Column>
  )
}

export const DropdownSideColumn = styled<DropdownColumnProps>(DropdownColumn)`
  justify-content: center;
  ${media.mq[1]} {
    flex: 0 0 50%;
    max-width: 480px;
    padding-left: 40px;
  }
  ${media.mq[2]} {
    flex: 0 0 28%;
    align-self: center;
  }
`

const DropdownContents: React.ComponentType<
  React.ElementConfig<'div'>
> = styled.div`
  --top--offset: 0px;
  position: absolute;
  top: var(--top--offset);
  left: 0px;
  height: 100%;
  width: 100%;
  padding: 30px;
  background-color: ${colors.peach100};
  max-height: calc(100vh - var(--top--offset));
  overflow: auto;
  ${media.mq[2]} {
    height: auto;
    --top--offset: ${variables.headerHeightFull}px;
    padding: 25px 35px 35px;
  }
`

const ContentsWrapper = styled.div`
  ${media.mq[1]} {
    display: flex;
    align-items: flex-start;
  }
`

const Column: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 0;
  &:not(:first-of-type) {
    margin-top: 25px;
  }
`

const ColumnItems = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
  > a {
    flex: 1 1 100%;
  }
  ${media.mq[2]} {
    column-gap: 40px;
    flex: 1;
    > a {
      max-width: 300px;
    }
  }
`

const ColumnTitle = styled(text.UppercaseOneLiner)`
  width: 100%;
  margin-bottom: 20px;
`

export const TriggerButton: any = styled.button`
  display: flex;
  align-items: center;
  line-height: 1.5;
  background: none;
  border: none;
  border-bottom: ${(p) =>
    p.ariaExpanded ? `1px solid ${colors.darkPurple100}` : 'none'};
  color: ${colors.darkPurple100};
  cursor: pointer;
  padding: 0;
  ${media.mq[2]} {
    :hover {
      color: ${(p) => (p.open ? colors.darkPurple60 : p.theme.primary)};
    }
  }
  :focus {
    outline-style: none;
  }

  :focus-visible {
    outline-style: auto;
  }
`

const BackContainer = styled.div`
  margin-bottom: 30px;
`

const GoBack = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-left: 20px;
    ${text.paragraph3};
    font-family: ${fonts.inter};
    color: ${colors.darkPurple100};
  }

  svg {
    width: 30px;
  }

  ${media.mq[2]} {
    display: none;
  }
`
