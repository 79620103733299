// @flow
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import * as text from '../text'

export const inputCss = css`
  background: transparent;
  border: 2px solid ${colors.darkPurple40};
  height: 50px;
  padding-left: 15px;
  caret-color: ${colors.darkPurple100};
  border-radius: 8px;
  &::placeholder {
    color: ${colors.darkPurple40};
  }
  ${text.paragraph3};
  color: ${colors.peach100};
`

export const validatedCss = css`
  background: ${colors.peach100};
  color: ${colors.darkPurple100} !important;
`

export const LabelWrapper = ({
  children,
  className,
  error,
  label,
}: {
  className?: string,
  children: React.Node,
  label?: React.Node,
  error?: React.Node,
}) => {
  return (
    <Root className={className} error={error}>
      <StyledLabelWrapper>{label}</StyledLabelWrapper>
      {children}
      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Root>
  )
}

export const ErrorWrapper = styled<{ children: React.Node }>('div')`
  ${text.paragraph3};
  text-align: left;
  color: ${colors.red};

  :not(:last-child) {
    margin-bottom: 20px;
  }

  :last-child {
    padding-top: 5px;
  }
`

const errorState = css`
  input {
    border-color: ${colors.red};
  }
`

const Root: React.ComponentType<any> = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 25px;

  &:first-of-type {
    margin-top: 0;
  }

  ${({ error }) => error && errorState}
`

const StyledLabelWrapper = styled.div`
  label {
    display: block;
    text-align: left;
    ${text.paragraph3};
    color: ${colors.peach100};
    margin-bottom: 10px;
  }
`
