// @flow

import * as React from 'react'

import { DAYS, ENTRIES, getFormattedUnit, getTime } from './utils'
import type { Day, EntryName, ErrorCode, TimeCardState, Unit } from './utils'

export const INITIAL_TIME_CARD: TimeCardState = DAYS.reduce(
  (timeCard: $Shape<TimeCardState>, day: string) => ({
    ...timeCard,
    [day]: {
      clockIn: { hours: '00', minutes: '00' },
      breakIn: { hours: '00', minutes: '00' },
      breakOut: { hours: '00', minutes: '00' },
      clockOut: { hours: '00', minutes: '00' },
      subtotal: 0, // daily total in minutes
      errorCode: null,
      erroredFields: [],
    },
  }),
  { total: 0 } // weekly total in minutes
)

type UpdateEntryPayload = {|
  +day: Day,
  +entry: EntryName,
  +unit: Unit,
  +value: string,
|}

type ProcessTimePayload = {|
  +day: Day,
  +entry: EntryName,
  +unit: Unit,
|}

export type Action = {|
  +type: string,
  +payload?: UpdateEntryPayload | ProcessTimePayload,
|}

type DispatchConfig = (Action) => void

export const context: React$Context<DispatchConfig> = React.createContext<DispatchConfig>(
  () => {}
)

export const ACTIONS = {
  updateEntry: (payload: UpdateEntryPayload) => ({
    type: ('updateEntry': 'updateEntry'),
    payload,
  }),
  processTime: (payload: ProcessTimePayload) => ({
    type: ('processTime': 'processTime'),
    payload,
  }),
  resetTimeCard: () => ({ type: ('resetTimeCard': 'resetTimeCard') }),
}

export const HANDLERS = {
  updateEntry: (
    timeCard: TimeCardState,
    { day, entry, unit, value }: UpdateEntryPayload
  ): TimeCardState => ({
    ...timeCard,
    [String(day)]: {
      ...timeCard[day],
      [String(entry)]: { ...timeCard[day][entry], [String(unit)]: value },
    },
  }),

  processTime: (
    timeCard: TimeCardState,
    { day, entry, unit }: ProcessTimePayload
  ): TimeCardState => {
    const dayCard = timeCard[day]
    const inputValue = dayCard[entry][unit]

    const times = {
      clockIn: getTime(dayCard.clockIn),
      breakIn: getTime(dayCard.breakIn),
      breakOut: getTime(dayCard.breakOut),
      clockOut: getTime(dayCard.clockOut),
    }

    const has = {
      clockIn: times.clockIn !== 0,
      breakIn: times.breakIn !== 0,
      breakOut: times.breakOut !== 0,
      clockOut: times.clockOut !== 0,
      clockTime: times.clockIn !== 0 && times.clockOut !== 0,
      breakTime: times.breakIn !== 0 && times.breakOut !== 0,
    }

    const clockTime = times.clockOut - times.clockIn
    const breakTime = times.breakOut - times.breakIn

    let errorCode: ErrorCode = null
    let erroredFields = []
    let subtotal = dayCard.subtotal

    ENTRIES.forEach((entry) => {
      const hours =
        dayCard[entry].hours === '' ? 0 : parseInt(dayCard[entry].hours)
      const minutes =
        dayCard[entry].minutes === '' ? 0 : parseInt(dayCard[entry].minutes)

      if ((!errorCode || errorCode === 'invalidNumber') && isNaN(hours)) {
        errorCode = 'invalidNumber'
        erroredFields.push(`${day}-${entry}-hours`)
      }

      if (
        (!errorCode || errorCode === 'invalidHours') &&
        (hours < 0 || hours > 23)
      ) {
        errorCode = 'invalidHours'
        erroredFields.push(`${day}-${entry}-hours`)
      }

      if ((!errorCode || errorCode === 'invalidNumber') && isNaN(minutes)) {
        errorCode = 'invalidNumber'
        erroredFields.push(`${day}-${entry}-minutes`)
      }

      if (
        (!errorCode || errorCode === 'invalidMinutes') &&
        (minutes < 0 || minutes > 59)
      ) {
        errorCode = 'invalidMinutes'
        erroredFields.push(`${day}-${entry}-minutes`)
      }
    })

    if (!errorCode) {
      switch (true) {
        case has.clockTime && clockTime < 0:
          errorCode = 'invalidClockTime'
          erroredFields = [`${day}-clock`]
          break
        case has.breakTime && breakTime < 0:
          errorCode = 'invalidBreakTime'
          erroredFields = [`${day}-break`]
          break
        case has.clockIn && has.breakIn && times.breakIn < times.clockIn:
          errorCode = 'invalidBreakStart'
          erroredFields = [`${day}-clockIn`, `${day}-breakIn`]
          break
        case has.clockOut && has.breakOut && times.breakOut > times.clockOut:
          errorCode = 'invalidBreakEnd'
          erroredFields = [`${day}-clockOut`, `${day}-breakOut`]
          break
        case has.clockTime && has.breakTime && clockTime < breakTime:
          errorCode = 'invalidTotalTime'
          erroredFields = [day]
          break
        default:
          if (clockTime >= 0 && breakTime >= 0 && clockTime >= breakTime) {
            subtotal = clockTime - breakTime
          }
      }
    }

    return {
      ...timeCard,
      [String(day)]: {
        ...dayCard,
        [String(entry)]: {
          ...dayCard[entry],
          [String(unit)]: getFormattedUnit(inputValue),
        },
        subtotal,
        errorCode,
        erroredFields,
      },
      total: timeCard.total - dayCard.subtotal + subtotal,
    }
  },

  resetTimeCard: () => INITIAL_TIME_CARD,
}
