// @flow

import * as React from 'react'

import { inspectAndTrack } from '@toggl/analytics'
import * as appLinks from '@toggl/app-links'

import template from './onClickLinkScript'
import type { DataLayer, GTag } from './types'

declare var window: {
  dataLayer: DataLayer,
  gtag: GTag,
  _inspectAndTrack: typeof inspectAndTrack,
}

if (typeof window !== 'undefined') {
  window._inspectAndTrack = inspectAndTrack
}

type Props = {
  gtmId: string,
}

export function activateOptimize() {
  if (typeof window === 'undefined') return
  if (!window.gtag) {
    window.gtag = function gtag(...args) {
      if (window.dataLayer) {
        window.dataLayer.push(args)
      }
    }
  }

  if (window._inspectAndTrack) {
    window._inspectAndTrack('optimize.activate', null, { heap: false })
  }
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'optimize.activate' })
  }
}

export function activateProfitWell() {
  if (typeof window === 'undefined') return
  if (!window.gtag) {
    window.gtag = function gtag(...args) {
      if (window.dataLayer) {
        window.dataLayer.push(args)
      }
    }
  }

  if (window._inspectAndTrack) {
    window._inspectAndTrack('start_pw', null, { heap: false })
  }
}

// Google Tag Manager `BODY` component.
export const Body = ({ gtmId }: Props): React.Element<'noscript'> => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
      height='0'
      width='0'
      style={{ display: 'none', visibility: 'hidden' }}
    />
  </noscript>
)

// Google Tag Manager `HEAD` component.
export const Head = ({ gtmId }: Props) => {
  return (
    <React.Fragment>
      <script
        data-cookieconsent='ignore'
        dangerouslySetInnerHTML={{ __html: consentScript }}
      />
      <script
        data-cookieconsent='ignore'
        dangerouslySetInnerHTML={{ __html: headSrc(gtmId) }}
      />
    </React.Fragment>
  )
}

export const allowConsent = () => {
  if (typeof window === 'undefined') return
  window.gtag?.('consent', 'update', {
    ad_personalization: 'granted',
    ad_storage: 'granted',
    ad_user_data: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  })
}

// Google Tag Manager `BODY` raw source.
export const body = (gtmId: string) => {
  if (!gtmId) return ''
  // TODO: Prepack and minify. https://github.com/toggl/public-web/issues/925
  const handlerSrcript = template
  return `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript><script>${handlerSrcript}</script>`
}

// Google Tag Manager `HEAD` raw source.
export const head = (gtmId: string) =>
  gtmId
    ? `<script data-cookieconsent="ignore">${consentScript}</script><script data-cookieconsent="ignore">${headSrc(
        gtmId
      )}</script>`
    : ''

const consentScript = `
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments)
      }
    }
    
    gtag('consent', 'default', {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    })
    gtag('set', 'ads_data_redaction', true)
    gtag('set', 'url_passthrough', true)
    
    if (window._inspectAndTrack) {
      window._inspectAndTrack('default_consent', null, { heap: false })
    }
`

const headSrc = (
  gtmId
) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`

const deskopAppLinks = [appLinks.APP_MACOS, appLinks.APP_WINDOWS]

export const onClickLink = (e: MouseEvent) => {
  if (e.target instanceof Element) {
    const anchor = e.target.closest('a')

    if (anchor instanceof HTMLAnchorElement) {
      const href = anchor.href

      if (href.includes(appLinks.EXTENSION_CHROME)) {
        window._inspectAndTrack(
          'browser_extension.clickthrough',
          { type: 'chrome' },
          { heap: false }
        )
      }

      if (href.includes(appLinks.EXTENSION_FIREFOX)) {
        window._inspectAndTrack(
          'browser_extension.clickthrough',
          { type: 'firefox' },
          { heap: false }
        )
      }

      if (href.includes(appLinks.EXTENSION_EDGE)) {
        window._inspectAndTrack(
          'browser_extension.clickthrough',
          { type: 'edge' },
          { heap: false }
        )
      }

      if (href.includes(appLinks.APP_IOS)) {
        window._inspectAndTrack(
          'mobile_app.clickthrough',
          { type: 'iOS' },
          { heap: false }
        )
      }

      if (href.includes(appLinks.APP_ANDROID)) {
        window._inspectAndTrack(
          'mobile_app.clickthrough',
          { type: 'Android' },
          { heap: false }
        )
      }

      if (deskopAppLinks.some((url) => href.includes(url))) {
        let type = ''

        if (href.includes(appLinks.APP_MACOS)) {
          type = 'macOS'
        } else if (href.includes(appLinks.APP_WINDOWS)) {
          type = 'Windows'
        } else {
          // Impossible state but if ever happens we want can capture the href
          // and check what happened in case we missed something
          type = href
        }
        window._inspectAndTrack(
          'desktop_app.clickthrough',
          { type },
          { heap: false }
        )
      }
    }
  }
}

export const onClickLinkSrc = template
