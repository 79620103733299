// @flow

import { Global, css } from '@emotion/react'
import * as React from 'react'

export const PrintStyles = () => {
  return (
    <Global
      styles={css`
        @media print {
          body,
          html {
            height: 100%;
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
          }
          * {
            background: transparent !important;
            color: #000 !important;
          }
          input {
            font-size: 15px !important;
            border-color: transparent !important;
          }
          div[class*='Information'] {
            border-top: none !important;
          }
          div[class*='Information'] input {
            padding: 0;
            margin-top: -10px !important;
            font-size: 20px !important;
            font-weight: bold;
          }
          .force-print {
            display: block !important;
          }
          div[class*='HeaderRow'],
          div[class*='FooterRow'] {
            margin: 20px 0 !important;
            padding-top: 15px !important;
          }
          div[class*='HeaderRow'] {
            border-bottom: 1px solid #999;
            padding-bottom: 25px;
          }
          div[class*='ComponentSection'] ~ *,
          div[class*='HeaderRoot'],
          div[class*='MiniBar'],
          header,
          div[class*='Wrapper'],
          div[class*='ContentWrapper'],
          .no-print,
          .gatsby-image-wrapper,
          button {
            display: none !important;
          }
        }
      `}
    />
  )
}
