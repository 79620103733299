// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import * as text from '../text'

export default function MenuContent({
  title,
  description,
  children,
}: {
  title: string,
  description: string,
  children?: React.Node,
}) {
  return (
    <div>
      <Title color={colors.darkPurple100}>{title}</Title>
      <text.P3 color={colors.darkPurple100}>{description}</text.P3>
      <Content>{children}</Content>
    </div>
  )
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`

const Title = styled(text.H4)`
  margin: 5px 0;
`
