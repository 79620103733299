// @flow

export const EXTENSION_CHROME =
  'https://chrome.google.com/webstore/detail/toggl-track-productivity/oejgccbfbmkkpaidnkphaiaecficdnfn'
export const EXTENSION_FIREFOX =
  'https://addons.mozilla.org/en-US/firefox/addon/toggl-button-time-tracker'
export const EXTENSION_EDGE =
  'https://microsoftedge.microsoft.com/addons/detail/toggl-track-productivity/nafkedmdnkdelpddmlkllfgdcpkelehh'
export const APP_IOS = 'https://apps.apple.com/us/app/toggl/id1291898086'
export const APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.toggl.giskard'
export const APP_WINDOWS =
  'https://toggl.com/track/toggl-desktop/downloads/windows/stable/TogglTrack-windows64.exe'
export const APP_MACOS =
  'https://apps.apple.com/us/app/toggl-track-hours-time-log/id1291898086'
