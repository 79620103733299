// @flow
import { Link as SourceLink, withPrefix } from 'gatsby'
import * as React from 'react'

import { useAllSitePages } from './hooks/useAllSitePages'
import { useAssets } from './hooks/useAssets'
import { useSiteUrl } from './hooks/useSiteUrl'

declare var process: WebProcess

// Component that's able to resolve an in-app link, asset reference or URL.
// Useful when a `href` can possibly represent either of these at once.
export const Link = ({
  children,
  href,
  rel,
  target,
  to,
  ...rest
}: {
  children?: React.Node,
  href?: string,
  rel?: string,
  target?: string,
  to?: string,
  ...
}): React.Element<typeof SourceLink> | React.Element<'a'> => {
  const allSitePages = useAllSitePages()
  const siteUrl = useSiteUrl()

  href = to ?? href ?? ''

  // XXX: Something is prepending weirdness in production builds for MDX sources,
  //      i.e. (https:/public-asssets-host/)relative/referennce.
  href = href?.replace(/^https:\/[\w-.]+\/(\w\/)?/, '/')

  const { asset } = useAssets({ asset: href })

  if (allSitePages.includes(href)) {
    return (
      <SourceLink {...rest} to={href} rel={rel} target={target}>
        {children}
      </SourceLink>
    )
  }

  if (asset) {
    return (
      <a {...rest} href={asset} rel={rel} target={target}>
        {children}
      </a>
    )
  }

  const url = new URL(href, siteUrl)
  const sameOrigin = new URL(siteUrl).origin

  rel = [rel?.split(' '), target && url.origin !== sameOrigin && 'noopener']
    .flat()
    .filter(Boolean)
    .join(' ')

  return (
    <a {...rest} href={withPrefix(href)} rel={rel || undefined} target={target}>
      {children}
    </a>
  )
}
