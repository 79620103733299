// @flow

export const breakpoints = {
  small: 320,
  mobile: 800,
  desktop: 1200,
  wide: 1440,
}

const breakPointsValues = [
  breakpoints.small,
  breakpoints.mobile,
  breakpoints.desktop,
  breakpoints.wide,
]

export const mq: Array<string> = breakPointsValues.map(
  (bp) => `@media (min-width: ${bp}px)`
)

export const mqbp: Array<string> = breakPointsValues.map(
  (bp) => `(min-width: ${bp}px)`
)

export const mqbpi: Array<string> = breakPointsValues.map(
  (bp) => `(max-width: ${--bp}px)`
)
