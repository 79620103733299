// @flow
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import { IconCheck } from '../icons/IconCheck'
import * as text from '../text'

type ListProps = {|
  children: React.Node,
  checkIcon?: boolean,
  checkPinkIcon?: boolean,
|}

type ItemProps = {|
  children: React.Node,
|}

export const Wrapper = ({ children, checkIcon, checkPinkIcon }: ListProps) => {
  return (
    <Ul checkIcon={checkIcon} checkPinkIcon={checkPinkIcon}>
      {children}
    </Ul>
  )
}

export const Item = ({ children }: ItemProps) => {
  return <Li>{children}</Li>
}

export const CheckItem = ({ children }: ItemProps) => {
  return (
    <CheckLi>
      <IconCheck color={colors.peach100} />
      {children}
    </CheckLi>
  )
}

export const CheckItemAlt = ({ children }: ItemProps) => {
  return <CheckLiAlt>{children}</CheckLiAlt>
}

const Ul: React.ComponentType<any> = styled.ul`
  padding-left: 20px;
  margin: 25px 0 40px;
  ${(p) =>
    p.checkIcon &&
    css`
      list-style: none;
      padding-left: 0;
      margin-bottom: 35px;
    `}
`

const Li: React.ComponentType<any> = styled.li`
  ${text.paragraph2};
  color: ${colors.peach100};
  margin: 15px 5px;
`

const CheckLi: React.ComponentType<any> = styled.li`
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 18px;
    flex-shrink: 0;
  }
`

const CheckLiAlt: React.ComponentType<any> = styled.li`
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  align-items: middle;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 16px;
  }
`
