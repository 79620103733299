// @flow
import * as React from 'react'

import * as session from './session'

export const context: React$Context<
  [boolean, (boolean) => void]
> = React.createContext<[boolean, (boolean) => void]>([false, () => {}])

export const Provider = (props: { children: React.Node }): React.Node => {
  const [isClient, setIsClient] = React.useState(false)
  const [hasSession, setHasSession] = React.useState(false)
  const sessionContext = [hasSession, setHasSession]

  React.useLayoutEffect(() => {
    setIsClient(true)
  }, [])

  React.useLayoutEffect(() => {
    if (!isClient) {
      return
    }

    setHasSession(session.check())
  }, [isClient])

  return (
    <context.Provider value={sessionContext}>{props.children}</context.Provider>
  )
}

export const { Consumer: Consumer } = context
