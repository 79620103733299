// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

type ErrorRowProps = {|
  +visible: Boolean,
|}

export const ErrorRow: React.ComponentType<
  React.ElementConfig<'div'>
> = styled.div`
  width: 100%;
  padding: 0 15px 10px;
  color: ${colors.red};
  transform: scaleY(${(p: ErrorRowProps) => (p.visible ? '1' : '0')});
  opacity: ${(p: ErrorRowProps) => (p.visible ? '1' : '0')};
  transition: transform 1s, margin 1s, opacity 1s;
  transition: opacity 0.3s 0.4s,
    transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    margin 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`
