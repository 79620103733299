import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors, fonts, media } from '@toggl/style'

const sharedStyles = css`
  font-family: ${fonts.gtHaptikMedium};
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  &:hover {
    background: ${colors.darkPurple80};
    color: ${colors.orange10};
  }
`

export const Wrapper = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: -4px;
  background: ${colors.darkPurple100};
  color: ${colors.orange10};

  ${sharedStyles};

  ${media.mq[1]} {
    font-size: 11px;
    padding: 2px 5px;
    background: ${colors.orange10};
    color: ${colors.darkPurple100};
  }
`

export const Small = styled.div`
  line-height: 1;
  font-size: 14px;
  border-radius: 100%;
  text-align: center;
  margin-top: -3px;
  padding: 3px;
  min-width: 20px;
  background: ${colors.orange10};
  color: ${colors.darkPurple100};
  ${sharedStyles};
`
