// @flow

import styled from '@emotion/styled'
import transparentize from 'polished/lib/color/transparentize'
import * as React from 'react'
import Modal from 'react-modal'

import { colors } from '@toggl/style'
import { Button, text } from '@toggl/ui'

type Props = {|
  +width?: number,
  +isOpen: boolean,
  +title: string,
  +body: string,
  +confirmText: string,
  +cancelText?: string,
  +onConfirm: () => void,
  +onCancel: () => void,
|}

export const ConfirmDialog = ({
  width = 500,
  isOpen,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: Props) => {
  const modalStyles = React.useMemo(
    () => ({
      overlay: {
        background: transparentize(0.3, colors.darkPurple90),
        zIndex: 100000,
      },
      content: {
        left: `calc(50% - ${width / 2}px)`,
        width: `${width}px`,
        height: 'fit-content',
        background: colors.peach100,
      },
    }),
    [width]
  )

  return (
    <Modal style={modalStyles} isOpen={isOpen} onRequestClose={onCancel}>
      <Container>
        <Title>{title}</Title>
        <Body>{body}</Body>
        <ButtonBar>
          <Button.Primary onClick={onConfirm}>{confirmText}</Button.Primary>
          {cancelText && (
            <Button.SecondaryTrack onClick={onCancel}>
              {cancelText}
            </Button.SecondaryTrack>
          )}
        </ButtonBar>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled(text.H4)`
  color: ${(p) => p.theme.primary};
`

const Body = styled(text.P3)`
  color: ${(p) => p.theme.light.text};
  margin: 30px 0;
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  > * {
    width: 180px;
    justify-content: center;
  }
`
