// @flow
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import * as React from 'react'

import { Badge } from '@toggl/ui'

import { GlobalLogin } from './GlobalLogin'
import { GlobalProductsDropdown } from './GlobalProducts'

export function GlobalHeaderContents({
  openPositions,
}: {
  openPositions: boolean,
}) {
  return (
    <React.Fragment>
      <GlobalProductsDropdown />
      <GlobalLogin />
      <li>
        <a href='https://toggl.com/blog/' rel='noreferrer' target='_blank'>
          Blog
        </a>
      </li>
      <li>
        <Link to='/about/'>About us</Link>
      </li>
      <li>
        <LastLink to='/jobs/'>
          Working at Toggl
          {openPositions && <Badge.Wrapper>We're hiring</Badge.Wrapper>}
        </LastLink>
      </li>
    </React.Fragment>
  )
}

const LastLink = styled(Link)`
  margin-right: auto !important;
`
