// @flow

import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { Hero, Link as UiLink, text } from '@toggl/ui'

import { LayoutGlobal } from './layouts'

declare var process: WebProcess

const ErrorPage = () => (
  <LayoutGlobal>
    <Hero.Wrapper
      bgImage={
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          src='../assets/hero-bg.jpg'
          loading='eager'
          alt='Hero background image'
          layout='fullWidth'
          placeholder='none'
          objectFit='cover'
          backgroundColor='black'
        />
      }
      bgVideo={require('assets/hero-bg-video.mp4').default}
      solidColor='black'
    >
      <StyledWrapper>
        <Hero.Title centered>Error</Hero.Title>
        <SmallTitle>
          Well this is embarrassing, we seem to be having some technical
          difficulties. We'll be back soon.
          <br />
          See our{' '}
          <a href='https://x.com/toggl'>
            <UiLink.Underlined>X (Twitter) page</UiLink.Underlined>
          </a>{' '}
          for updates.
        </SmallTitle>
      </StyledWrapper>
    </Hero.Wrapper>
  </LayoutGlobal>
)

const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 76px 20px 168px 20px;
  }
`

const SmallTitle = styled.div`
  ${text.paragraph2};
  color: ${colors.orange10};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

export default ErrorPage
