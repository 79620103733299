// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import typeof { AnnouncementBar } from './AnnouncementBar'
import typeof * as Navigation from './navigation'

type Props = {
  children:
    | [
        ?React.Element<AnnouncementBar>,
        (
          | React.Element<$PropertyType<Navigation, 'Header'>>
          | React.Element<$PropertyType<Navigation, 'FluidHeader'>>
        )
      ]
    | [
        | React.Element<$PropertyType<Navigation, 'Header'>>
        | React.Element<$PropertyType<Navigation, 'FluidHeader'>>
      ],
  ...
}

export const Header = styled<Props>('header')`
  width: 100%;
  position: absolute;
  pointer-events: none;
  height: 100%;
  // FIXME: Value set unreasonably high, because of other unreasonable uses of z-index in the codebase.
  z-index: 9999;
`
