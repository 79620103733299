// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { Input } from '@toggl/ui'

import { ACTIONS, context } from './reducer'
import { MOBILE_VIEW_WIDTH } from './utils'
import type { Day, EntryName, Unit } from './utils'

type Props = {|
  +day: Day,
  +entry: EntryName,
  +unit: Unit,
  +value: string,
  +erroredFields?: string[],
|}

export const TimeUnitInput = React.memo<Props>(
  ({ day, entry, unit, value, erroredFields = [] }: Props) => {
    const dispatch = React.useContext(context)

    const handleFocus = React.useCallback(
      (e: SyntheticFocusEvent<HTMLInputElement>): void =>
        e.currentTarget.select(),
      []
    )

    const handleChange = React.useCallback(
      (e: SyntheticKeyboardEvent<HTMLInputElement>): void =>
        dispatch(
          ACTIONS.updateEntry({
            day,
            entry,
            unit,
            value: e.currentTarget.value,
          })
        ),
      [day, dispatch, entry, unit]
    )

    const handleBlur = React.useCallback(
      () => dispatch(ACTIONS.processTime({ day, entry, unit })),
      [day, dispatch, entry, unit]
    )

    const name = `${day}-${entry}-${unit}`
    const hasError = erroredFields.some((prefix) => name.startsWith(prefix))
    return (
      <StyledInput
        id={name}
        name={name}
        type='text'
        value={value}
        error={hasError}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )
  }
)

const StyledInput: React.ComponentType<React.ElementConfig<'div'>> = styled(
  Input
)`
  height: 35px;
  margin-top: 0;

  input {
    width: 100%;
    height: 35px;
    padding-left: 11px;
  }

  @media screen and (max-width: ${MOBILE_VIEW_WIDTH}) {
    width: auto;

    :first-of-type {
      text-align: right;
    }

    :last-of-type {
      text-align: left;
    }

    input {
      width: 42px;
    }
  }
`
