// @flow

import * as Sentry from '@sentry/gatsby'
import { typeof navigate as Navigate } from 'gatsby'

import { getAuthUrl } from './lib/url'
import type { PricingPlan, State } from './types'

declare var process: WebProcess

export type AuthErrorLocationState = {
  +error: string,
}

// Redirect olden location states to Track auth.
export const redirectLegacyAuthCallbacks = () => {
  try {
    const url = new URL(window.location)

    if (!url.searchParams.has('state')) {
      return
    }

    const authUrl = new URL(
      window.location.search,
      getAuthUrl(process.env.GATSBY_SITE_URL)
    ).href

    window.location.assign(authUrl)
  } catch (err) {
    Sentry.captureException(err)
  }
}

// Handle `?authError` location state.
export const handleAuthErrors = (
  navigate: Navigate<AuthErrorLocationState>,
  pricingPlanUrls: Map<PricingPlan, string>
): any | void => {
  try {
    const url = new URL(window.location)

    if (!url.searchParams.has('authError')) {
      return
    }

    const authError = JSON.parse(atob(url.searchParams.get('authError') ?? ''))

    if (!(authError.message && authError.metaData)) {
      return Sentry.captureException(
        new Error('Received auth erorr with missing data'),
        {
          contexts: {
            'auth error': authError,
          },
        }
      )
    }

    Sentry.captureException(new Error(authError.message), {
      contexts: {
        'auth error': authError.metaData,
      },
    })

    const state: State = authError.metaData.state

    if (typeof state !== 'object') {
      return Sentry.captureException(
        new Error('Received incorrect auth error state'),
        {
          contexts: {
            'auth error': authError,
          },
        }
      )
    }

    switch (state.type) {
      case 'apple-profile': {
        return
      }
      case 'profile': {
        return
      }
      case 'apple-sign-in': {
        const { fromTogglButton } = state

        if (fromTogglButton) {
          return navigate('/track/toggl-button-login/', {
            state: {
              error: authError.message,
            },
          })
        }

        return navigate('/track/login/', {
          state: {
            error: authError.message,
          },
        })
      }
      case 'apple-sign-up': {
        const { planId } = state
        const url = pricingPlanUrls.get(state.planId)

        if (!url) {
          Sentry.captureException(
            new Error('Could not resolve signup URL for pricing plan'),
            {
              contexts: {
                'Plan ID': planId,
              },
            }
          )

          return navigate('/track/signup/', {
            state: {
              error: authError.message,
            },
          })
        }

        return navigate(url)
      }
      case 'apple-sso-link': {
        return Sentry.captureException(new Error('Not implemented'))
      }
      case 'google-sign-in': {
        const { fromTogglButton } = state

        if (fromTogglButton) {
          return navigate('/track/toggl-button-login/', {
            state: {
              error: authError.message,
            },
          })
        }

        return navigate('/track/login/', {
          state: {
            error: authError.message,
          },
        })
      }
      case 'google-sign-up': {
        const { planId } = state
        const url = pricingPlanUrls.get(state.planId)

        if (!url) {
          Sentry.captureException(
            new Error('Could not resolve signup URL for pricing plan'),
            {
              contexts: {
                'Plan ID': planId,
              },
            }
          )

          return navigate('/track/signup/', {
            state: {
              error: authError.message,
            },
          })
        }

        return navigate(url)
      }
      case 'google-sso-link': {
        return Sentry.captureException(new Error('Not implemented'))
      }
      default:
        ;(state.type: empty)

        return Sentry.captureException(
          new Error('Received unexpected state type'),
          {
            contexts: {
              state,
            },
          }
        )
    }
  } catch (err) {
    Sentry.captureException(err)
  }
}
