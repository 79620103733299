// @flow

const init = () => {
  // avo-inspector is browser-only
  if (
    typeof window === 'undefined' ||
    typeof process.env.JEST_WORKER_ID !== 'undefined'
  ) {
    return null
  }

  const Inspector = require('avo-inspector')

  const inspector = new Inspector.AvoInspector({
    apiKey: process.env.GATSBY_AVO_INSPECTOR_API_KEY,
    env: getAvoEnv(),
    version: process.env.GATSBY_VERSION ?? '',
    appName: 'Public Web',
  })

  return inspector
}

export const avoInspector = init()

function getAvoEnv() {
  if (process.env.PRODUCTION) {
    return 'prod'
  } else if (process.env.STAGING) {
    return 'staging'
  } else {
    return 'dev'
  }
}
