// @flow
import { avoInspector } from '@toggl/avo'
import { filterData } from '@toggl/avo/src/utils'

// A wrapper over Avo, Heap, Google Analytics to track events in one place.
export const inspectAndTrack = (
  eventName: string,
  data: { [key: string]: any } | null = {},
  options: {
    avo?: boolean,
    heap?: boolean,
    ga?: boolean,
    callback?: () => void,
  } = {
    avo: true,
    heap: true,
    ga: true,
  }
): void => {
  if (typeof window === 'undefined') return

  const userHasConsentedToAnalytics = !!window.Cookiebot?.consent?.statistics

  const trackAvo = options.avo ?? true
  const trackHeap = options.heap ?? true
  const trackGa = options.ga ?? true

  const filteredData = filterData(data)
  const filteredDataGa = options.callback
    ? { ...filteredData, event_callback: options.callback }
    : filteredData

  if (trackAvo) {
    avoInspector?.trackSchemaFromEvent(eventName, filteredData ?? {})
  }

  if (userHasConsentedToAnalytics) {
    if (trackHeap && window.posthog) {
      window.posthog.capture(eventName, filteredData)
    }

    if (trackGa && window.gtag) {
      window.gtag('event', eventName, filteredDataGa ?? {})
    }
  }
}
