// @flow
import { graphql, useStaticQuery } from 'gatsby'

export const useSiteUrl = (): string => {
  const data: ?{
    +site: { +siteMetadata: { +siteUrl: string } },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  if (!data) {
    throw new Error('Received empty query')
  }

  return data.site.siteMetadata.siteUrl
}
