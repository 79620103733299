// @flow

export const heading1 = '1.1'
export const heading2 = '1.25'
export const heading3 = '1.3'
export const heading4 = '1.35'
export const heading5 = '1.5'

export const paragraph1 = '1.7'
export const paragraph2 = '1.7'
export const paragraph3 = '1.6'
export const paragraph4 = '1.35'
export const paragraph5 = '1.35'
