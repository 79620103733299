// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import { media } from '@toggl/style'

type Props = {|
  children: React.Node,
|}

export const MobileOnly = ({ children }: Props) => {
  return <Mobile>{children}</Mobile>
}

export const MobileAndTabletOnly = ({ children }: Props) => {
  return <MobileTablet>{children}</MobileTablet>
}

export const DesktopOnly = ({ children }: Props) => {
  return <Desktop>{children}</Desktop>
}

export const WideDesktopOnly = ({ children }: Props) => {
  return <WideDesktop>{children}</WideDesktop>
}

const Mobile: React.ComponentType<any> = styled.div`
  ${media.mq[1]} {
    display: none;
  }
`

const MobileTablet: React.ComponentType<any> = styled.div`
  ${media.mq[2]} {
    display: none;
  }
`

const Desktop: React.ComponentType<any> = styled.div`
  display: none;
  ${media.mq[1]} {
    display: inherit;
  }
`
const WideDesktop: React.ComponentType<any> = styled.div`
  display: none;
  ${media.mq[2]} {
    display: inherit;
  }
`
