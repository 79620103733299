// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors } from '@toggl/style'

import { LabelWrapper, inputCss, validatedCss } from './styles'

type InputProps = {
  ...React.ElementConfig<'input'>,
  className?: string,
  label?: React.Node,
  error?: React.Node,
  id: string,
  name: string,
  onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  placeholder?: string,
  value?: string,
  disabled?: boolean,
  readonly?: boolean,
}

export const Input = ({
  className,
  label,
  error,
  placeholder,
  type,
  id,
  name,
  value,
  ...props
}: InputProps) => (
  <LabelWrapper className={className} label={label} error={error}>
    <StyledInput
      {...props}
      id={id}
      validated={value && error === null}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
    />
  </LabelWrapper>
)

const StyledInput: React.ComponentType<any> = styled.input`
  ${inputCss};
  ::placeholder {
    color: inherit;
    opacity: 0.65;
  }
  &:focus {
    border-color: ${colors.peach100};
    background: ${colors.peach100};
    color: ${colors.darkPurple100};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 24px ${colors.peach100} inset !important;
  }

  ${(p) => (p.validated ? validatedCss : '')};
`
