// @flow
import * as React from 'react'

import { useSession } from '@toggl/auth'
import { Navigation } from '@toggl/ui'

import { getAccountsUrl, getTrackLoginUrl } from '../../../utils'

declare var process: WebProcess

const products = {
  title: 'Log in to Toggl',
  subtitle: 'Choose a product to log in',
  items: [
    {
      image: require('components/layouts/assets/nav-toggl-track.svg').default,
      name: 'Toggl Track',
      title: 'track',
      url: getTrackLoginUrl(),
      subtitle: 'Effortless time tracking',
    },
    {
      image: require('components/layouts/assets/nav-toggl-plan.svg').default,
      name: 'Toggl Plan',
      title: 'plan',
      subtitle: 'Beautifully simple team planning',
      url: new URL('/plan/login', getAccountsUrl()).toString(),
    },
    {
      image: require('components/layouts/assets/nav-toggl-hire.svg').default,
      name: 'Toggl Hire',
      title: 'hire',
      subtitle: 'Easy full-cycle hiring',
      url: 'https://hire.toggl.com/admin',
    },
  ],
}

export function GlobalLogin() {
  return (
    <Navigation.DropdownItemContents item='global-login'>
      <GlobalLoginContent />
    </Navigation.DropdownItemContents>
  )
}

export function GlobalLoginTrigger() {
  return (
    <Navigation.DropdownTrigger item='global-login'>
      <span>Log in</span>
    </Navigation.DropdownTrigger>
  )
}

function GlobalLoginContent() {
  const [hasTrackSession] = useSession()
  return (
    <Navigation.MenuContent
      title={products.title}
      description={products.subtitle}
    >
      <Navigation.ProductsGroup>
        {products.items.map((item) => {
          return (
            <a
              key={item.url}
              aria-label={`Log into ${item.name}`}
              href={
                item.title === 'track' && hasTrackSession
                  ? process.env.GATSBY_TRACK_APP_URL
                  : item.url
              }
            >
              <Navigation.Product
                key={item.url}
                img={item.image}
                title={item.title}
                description={item.subtitle}
                mediaWidth='284'
                mediaHeight='178'
              />
            </a>
          )
        })}
      </Navigation.ProductsGroup>
    </Navigation.MenuContent>
  )
}
